import HEADER from "../components/header";
import HEROHEADER from "../components/heroheader";
import FOOTER from "../components/footer";
import React, { useEffect } from 'react';
import { HeartIcon, UserGroupIcon } from '@heroicons/react/solid'
import { CashIcon } from '@heroicons/react/outline'
import GALLERY from "../components/gallery";

const benefitsLinks = [
  {
    name: 'Billigste Kontingent',
    description:
      'Vi tilbyder det billigste kontingent til POLIS medlemmer. Det gør vi eftersom, at vi vægter, at der skal være plads til studerende, og hvor de er i fokus.',
    icon: CashIcon,
  },
  {
    name: 'Socialt Fællesskab',
    description:
      'Vi er kendt for det gode fællesskab vi skaber. I POLIS I.K går ALLE op i, at vi har et godt socialt miljø.',
    icon: HeartIcon,
  },
  {
    name: 'Nyt Netværk',
    description:
      'I foreningen har du mulighed for at være en del at et fællesskab med andre studerende. Vi tilbyder en unik mulighed for at udvide dit netværk i Aalborg.',
    icon: UserGroupIcon,
  },
]

export default function FRONTPAGE() {

  useEffect(() => {
    document.title = "Forside | POLIS IK";
  });

  return (
    <>
      <HEADER />
      <HEROHEADER />
      <div className="bg-white">
        {/* Overlapping cards */}
        <div
          className="-mt-38 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8 text-left"
          aria-labelledby="contact-heading"
        >
          <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
            {benefitsLinks.map((link) => (
              <div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl">
                <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                  <div className="absolute top-0 p-5 inline-block bg-blue-500 rounded-xl shadow-lg transform -translate-y-1/2">
                    <link.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <h3 className="text-xl font-medium text-gray-900">{link.name}</h3>
                  <p className="mt-4 text-base text-gray-500">{link.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
          GALLERI
        </h2>
      </div>
      <GALLERY />
      <FOOTER />

    </>
  );
}
import Titles from '../components/global-variables';
import FOOTER from '../components/footer'
import HEADER from '../components/header'
import img_wallpaper from "../images/wallpaper.jpg"
import React, { useEffect } from 'react';
import { MailIcon, PhoneIcon } from '@heroicons/react/solid'
import img_juel from "../images/boardmembers/juels.jpg"
import img_cooper from "../images/boardmembers/coops.jpg"
import img_pedersen from "../images/boardmembers/capper.jpg"

const footballmale = [
  {
    name: 'Sebastian McCall Cooper',
    title: 'Træner',
    email: 'polisboldklub@gmail.com',
    telephone: '+4560168951',
    imageUrl: img_cooper
  },
  {
    name: 'Phillip Juel Nielsen',
    title: 'Træner',
    email: 'polisboldklub@gmail.com',
    telephone: '+45 ?? ?? ?? ??',
    imageUrl: img_juel
  },
  {
    name: 'Casper Jahreis Pedersen',
    title: 'Træner',
    email: 'polisboldklub@gmail.com',
    telephone: '+4561339921',
    imageUrl: img_pedersen
  },
  // More people...
]

const footballwomen = [
  {
    name: 'test',
    title: 'test',
    email: 'test',
    telephone: '',
    imageUrl: img_cooper
  },
  // More people...
]


export default function CONTACTTRAINERS() {

  useEffect(() => {
    document.title = "Kontakt Trænere | POLIS IK";
  });

  return (
    <>
      <HEADER />
      <div className="relative bg-black">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src={img_wallpaper}
            alt=""
          />
          <div className="absolute inset-0 bg-black opacity-60" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">{Titles.contactTrainersTitle}</h1>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-12">
          <div className="divide-y-2 divide-white">
            <div className="py-12 space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
              <h2 className="pt-12 text-xl font-extrabold tracking-tight sm:text-4xl text-black">FODBOLD HERRE</h2>
            </div>
            {/* Fjern pb-24 når kvinder bliver aktuelt */}<ul className="pb-24 pl-4 pr-4 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
              {footballmale.map((person) => (
                <li
                  key={person.email}
                  className="col-span-1 flex flex-col text-center bg-white rounded-lg drop-shadow-md divide-y divide-gray-200"
                >
                  <div className="flex-1 flex flex-col p-8">
                    <img className="object-cover h-32 w-32 flex-shrink-0 mx-auto rounded-full" src={person.imageUrl} alt="" />
                    <h3 className="mt-6 text-gray-900 text-sm font-medium">{person.name}</h3>
                    <dl className="mt-1 flex-grow flex flex-col justify-between">
                      <dt className="sr-only">Rolle</dt>
                      <dd className="text-gray-500 text-md">{person.title}</dd>
                    </dl>
                  </div>
                  <div>
                    <div className="-mt-px flex divide-x divide-gray-200">
                      <div className="w-0 flex-1 flex">
                        <a
                          href={`mailto:${person.email}`}
                          className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                        >
                          <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                          <span className="ml-0.5 tracking-tight text-sm">{person.email}</span>
                        </a>
                      </div>
                      <div className="-ml-px w-0 flex-1 flex">
                        <a
                          href={`tel:${person.telephone}`}
                          className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                        >
                          <PhoneIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                          <span className="ml-0.5 tracking-tight text-sm">{person.telephone}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {/*<div className="py-12 space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
              <h2 className="pt-12 text-xl font-extrabold tracking-tight sm:text-4xl text-black">FODBOLD KVINDER</h2>
            </div>
            <ul className="pb-24 pl-4 pr-4 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
              {footballwomen.map((person) => (
                <li
                  key={person.email}
                  className="col-span-1 flex flex-col text-center bg-white rounded-lg drop-shadow-md divide-y divide-gray-200"
                >
                  <div className="flex-1 flex flex-col p-8">
                    <img className="object-cover h-32 w-32 flex-shrink-0 mx-auto rounded-full" src={person.imageUrl} alt="" />
                    <h3 className="mt-6 text-gray-900 text-sm font-medium">{person.name}</h3>
                    <dl className="mt-1 flex-grow flex flex-col justify-between">
                      <dt className="sr-only">Rolle</dt>
                      <dd className="text-gray-500 text-md">{person.title}</dd>
                    </dl>
                  </div>
                  <div>
                    <div className="-mt-px flex divide-x divide-gray-200">
                      <div className="w-0 flex-1 flex">
                        <a
                          href={`mailto:${person.email}`}
                          className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                        >
                          <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                          <span className="ml-0.5 tracking-tight text-sm">{person.email}</span>
                        </a>
                      </div>
                      <div className="-ml-px w-0 flex-1 flex">
                        <a
                          href={`tel:${person.telephone}`}
                          className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                        >
                          <PhoneIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                          <span className="ml-0.5 tracking-tight text-sm">{person.telephone}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
              </ul>*/}
          </div>
        </div>
      </div>
      <FOOTER />
    </>
  )
}
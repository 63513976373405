/* This file contains all global varibales used for the website */
const Titles = {
    footballTitle: 'FODBOLD',
    sponsorTitle: 'SAMARBEJDSPARTNERE',
    boardmembersTitle: 'BESTYRELSEN',
    aboutTitle: 'OM KLUBBEN',
    contactUsTitle: 'KONTAKT OS',
    contactTrainersTitle: 'KONTAKT TRÆNERSTABEN',
    linksTitle: 'LINKS',
    signupTitle: 'TILMELDING',
    trialSignupTitle: 'PRØVEPERIODE',
}

/* Exporting the variables */
export default Titles;
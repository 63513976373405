import React from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';

const config = {
    width: "400px",
    height: "500px",
    floating: true,
    headerTitle: 'POLIS IK Chat Bot',
    placeholder: 'Indtast venligst din besked',
    hideUserAvatar: true,
};

const theme = {
    background: '#f5f8fb',
    headerBgColor: '#2563EB',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#2563EB',
    botFontColor: '#fff',
    userBubbleColor: '#fff',
    userFontColor: '#4a4a4a',
};

export default function CHATBOT() {

    return (
        <>
            <ThemeProvider theme={theme}>
                <ChatBot
                    recognitionEnable={true}
                    steps={[
                        {
                            id: 'user-message',
                            message: 'Goddag! Jeg er POLIS IKs Chat Bot!',
                            trigger: 'user-initiate',
                        },
                        {
                            id: 'user-initiate',
                            message: 'Hvad er dit navn?',
                            trigger: 'user-initiate2',
                        },
                        {
                            id: 'user-initiate2',
                            user: true,
                            trigger: 'intro',
                        },
                        {
                            id: 'intro',
                            message: 'Goddag! Hvordan kan jeg hjælpe dig?',
                            trigger: 'intro-user',
                        },
                        {
                            id: 'intro-user',
                            message: 'Her er følgende måder jeg kan hjælpe dig på',
                            trigger: 'intro-user2',
                        },
                        {
                            id: 'intro-user2',
                            options: [
                                { value: 'address', label: 'Hvad er jeres adresse?', trigger: 'address-response' },
                                { value: 'contact', label: 'Hvordan kan jeg kontakte jer?', trigger: 'contact-response' },
                                { value: 'trial', label: 'Tilbyder i en prøveperiode?', trigger: 'trial-response' },
                                { value: 'other', label: 'Andet', trigger: 'other-response' },
                            ]
                        },
                        {
                            id: 'intro-user4',
                            options: [
                                { value: 'yes', label: 'Ja', trigger: 'intro-user' },
                                { value: 'no', label: 'Nej', trigger: 'no-response' },
                            ]
                        },
                        {
                            id: 'no-response',
                            message: 'Det er jeg ked af at høre. Du må have en god dag!',
                            end: true,
                        },
                        {
                            id: 'intro-user3',
                            message: 'Kan jeg hjælpe dig på andre måder?',
                            trigger: 'intro-user4',
                        },
                        {
                            id: 'address-response',
                            message: 'Vores addresse er: Thomas Manns vej 20, 9220 Aalborg Øst',
                            trigger: 'intro-user3',
                        },
                        {
                            id: 'contact-response',
                            message: 'Du kan finde kontakt information under fanen "Kontakt Os" eller du kan få fat på os på følgende måder: Email: polisboldklub@gmail.com Telefon: +45 60 16 89 51',
                            trigger: 'intro-user3',
                        },
                        {
                            id: 'trial-response',
                            message: 'Ja, vi tilbyder en prøveperiode på 14 dage, hvor man har mulighed for at deltage til to prøvetræninger. Det kræves dog at du opskriver dig til denne prøveperiode, hvilket kan gøres her på hjemmesiden under "Tilmeld dig", hvor du vil finde et link til opskrivelse til prøveperioden i bunden.',
                            trigger: 'intro-user3',
                        },
                        {
                            id: 'other-response',
                            message: 'Hvis du har andre spørgsmål, så kan du kontakte os på vores officielle Facebook side.',
                            trigger: 'intro-user3',
                        },
                    ]}
                    {...config}
                />
            </ThemeProvider>
        </>
    );
}
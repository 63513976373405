import Titles from "../components/global-variables"
import img_wallpaper from "../images/wallpaper.jpg"
import HEADER from "../components/header"
import FOOTER from "../components/footer"
import React, { useEffect } from 'react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { Disclosure } from '@headlessui/react'

const testimonials = [
    {
        name: "Søren 24 år",
        answer: '"POLIS IK er trods sin spæde alder allerede en helt igennem fremragende klub at spille i, hvor der er masser af holdånd og fællesskab. Med såvel dygtige frivillige kræfter i bestyrelsen og trænerstaben som sociale og hyggelige holdkammerater er POLIS IK et fantastisk sted at få afbræk fra studierne. Når klubben samtidigt tager udgangspunkt i et samfundsfagligt fællesskab gennem moderforeningen POLIS AAU, er der optimal grobund for fælles humor og interne jokes. Alt i alt er det en stor fornøjelse at være del af." - (Fodbold Herre)',
    },
    {
        name: "Frederik 21 år",
        answer: '"POLIS IK er et fantastisk tilbud. Udover byens billigste kontingent, er det også en god måde at møde nye bekendtskaber fra andre studier og årgange. Foruden træninger og kampe er vi også stærkt tilstede i 3. halvleg. Der er ingen undskyldninger for ikke at melde sig ind!" - (Fodbold Herre)',
    },
    {
        name: "Phillip 20 år",
        answer: '"Jeg har haft en meget hyggelig start her i klubben, hvori der også er blevet opfulgt med en hel del arrangementer, hvor vi er begyndt at kende hinanden ikke kun på fodbold-niveauet, men også det personlige niveau. Nogle af mine bedste bekendskaber er her i klubben og det er en super vigtig forening for POLIS" - (Fodbold Herre)',
    },
    {
        name: "Marcus 24 år",
        answer: '"Jeg har haft det mega fedt ved at starte i POLIS IK. Særligt når man kommer langvejsfra og ikke kender specielt mange, så er det en fed måde at lære folk at kende på, både på og uden for studiet. Her er et klasse engangement og fælleskab i en forholdsvis nyopstartet klub" - (Fodbold Herre)',
    },
    {
        name: "Mathias 24 år",
        answer: '"POLIS IK har været så fedt et fællesskab at være en del af ved siden af studiet. Det har været fantastisk at møde nye ansigter og hvor der er plads til alle niveauer. Og så er det billigt!" - (Fodbold Herre)',
    },
]

const pricing = {
    tiers: [
        {
            title: 'Prøveperiode',
            price: 0,
            frequency: '/gratis',
            description: 'Dette er en prøveperiode du opskriver dig til, for at se om POLIS IK er noget for dig.',
            features: [
                '2 prøvetræninger',
                '14 dages prøveperiode',
            ],
            cta: 'Opskriv mig',
            href: 'opskriv-proeveperiode',
            mostPopular: false,
        },
        {
            title: 'POLIS IK Medlem',
            price: 250,
            frequency: '/halvårligt',
            description: 'OBS: Det er et krav at være POLIS AAU Medlem for at blive POLIS IK Medlem.',
            features: [
                'Deltag til alle træninger',
                'Deltag i DBU kampe',
                'Deltag i fælles arrangementer',
            ],
            cta: 'Tilmeld mig',
            href: 'tilmeld-dig',
            mostPopular: false,
        },
        {
            title: 'POLIS AAU Medlem',
            price: 100,
            frequency: '/engangsbetaling',
            description: 'POLIS AAU er en social og faglig forening for studerende tilknyttet Skolen for Statskundskab ved Aalborg Universitet.',
            features: [
                'Medlem resten af din studietid',
                'Diverse fordele',
            ],
            cta: 'Bliv medlem',
            href: 'https://polis.nemtilmeld.dk/24/?fbclid=IwAR3jFhCoNExFX_5Q6jq3ZdAArrJN1207iB2ftdUec6D9_iH7wgMbc7wBjig',
            mostPopular: false,
        },
    ],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ABOUTPAGE() {

    useEffect(() => {
        document.title = "Om Klubben | POLIS IK";
    });

    return (
        <>
            <HEADER />
            <div className="relative bg-black">
                <div className="absolute inset-0">
                    <img
                        className="w-full h-full object-cover"
                        src={img_wallpaper}
                        alt=""
                    />
                    <div className="absolute inset-0 bg-black opacity-60" aria-hidden="true" />
                </div>
                <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">{Titles.aboutTitle}</h1>
                </div>
            </div>
            <div className="relative py-16 bg-white overflow-hidden">
                <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                    <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                        <svg
                            className="absolute top-12 left-full transform translate-x-32"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                        >
                            <defs>
                                <pattern
                                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                        </svg>
                        <svg
                            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                        >
                            <defs>
                                <pattern
                                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                        </svg>
                    </div>
                </div>
                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                        <h1>
                            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                HVEM ER VI?
                            </span>
                        </h1>
                    </div>
                    <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto text-left">
                        <p>
                            POLIS Idrætsklub, kaldet POLIS I.K, er en forening, som tilbyder en sjov og aktiv hverdag til SU-venlige priser. I øjeblikket har foreningen èn afdeling som er fodbold. Klubben tager med glæde imod nye medlemmer uanset hvornår på sæsonen, man vælger at starte. Foreningen er tiltænkt medlemmer som går på Aalborg Universitet og er medlem af POLIS AAU. Pris på medlemsskab kan der læses mere om længere nede under "<a href="#medlemspris_id" className="text-blue-500">Medlemspris</a>". POLIS I.K er et sted, hvor man i sportslige rammer møder andre studerende på tværs af forskellige semestre.
                        </p>
                        <p>
                            Lyder vi som en idrætsforening for dig? Så kan du kan finde nærmere information om vores sportsgrene under "Afdelinger" øverst på siden.
                        </p>
                    </div>
                </div>
            </div>
            <div className=" mx-auto py-24 px-4 bg-white sm:px-6 lg:px-8">
                <h2 id="medlemspris_id" className="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl text-left">
                    MEDLEMSPRIS
                </h2>

                {/* Tiers */}
                <div className="mt-24 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
                    {pricing.tiers.map((tier) => (
                        <div
                            key={tier.title}
                            className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
                        >
                            <div className="flex-1">
                                <h3 className="text-xl font-semibold text-gray-900">{tier.title}</h3>
                                {tier.mostPopular ? (
                                    <p className="absolute top-0 py-1.5 px-4 bg-blue-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                                        Mest populær
                                    </p>
                                ) : null}
                                <p className="mt-4 flex items-baseline text-gray-900">
                                    <span className="text-5xl font-extrabold tracking-tight">{tier.price} kr</span>
                                    <span className="ml-1 text-xl font-semibold">{tier.frequency}</span>
                                </p>
                                <p className="mt-6 text-gray-500">{tier.description}</p>

                                {/* Feature list */}
                                <ul className="mt-6 space-y-6">
                                    {tier.features.map((feature) => (
                                        <li key={feature} className="flex">
                                            <CheckIcon className="flex-shrink-0 w-6 h-6 text-blue-500" aria-hidden="true" />
                                            <span className="ml-3 text-gray-500">{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <a
                                href={tier.href} target="_pop"
                                className={classNames(
                                    tier.mostPopular
                                        ? 'bg-blue-500 text-white hover:bg-blue-600'
                                        : 'bg-blue-50 text-blue-700 hover:bg-blue-100',
                                    'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'
                                )}
                            >
                                {tier.cta}
                            </a>
                        </div>
                    ))}
                </div>
            </div>
            <div className="relative py-16 bg-white overflow-hidden">
                <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                    <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                        <svg
                            className="absolute top-12 left-full transform translate-x-32"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                        >
                            <defs>
                                <pattern
                                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                        </svg>
                        <svg
                            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                        >
                            <defs>
                                <pattern
                                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                        </svg>
                        <svg
                            className="absolute bottom-12 left-full transform translate-x-32"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                        >
                            <defs>
                                <pattern
                                    id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                        </svg>
                    </div>
                </div>
                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="prose prose-indigo prose-lg text-gray-500 mx-auto text-left">
                        <h2 className="text-black" id="information_section">INFORMATION</h2>
                        <strong className="text-gray-900">Nyt medlem i POLIS I.K eller lyster en prøveperiode blot?</strong>
                        <p>
                            Alle nye er velkommen til 2 gange gratis prøvetræninger hos POLIS I.K. Herefter vil man være forpligtet til at betale kontingent. Det er ikke et krav at meddele om du kommer, men du har krav på at opskrive dig til den gratis prøveperiode ved at udfylde denne <a href="opskriv-proeveperiode" className="text-blue-500" target="_pop">formular</a>. Hvis du ønsker at tale med en af trænerne eller vores bestyrelse inden, så er du selvfølgelig også altid velkommen til at kontakte dem. Kontaktoplysninger til bestyrelsen og trænere kan findes under fanen "Kontakt".
                        </p>
                        <h2 className="text-black" id="information_section">KLUBBENS VÆRDIER</h2>
                        <p>
                            For POLIS Idrætsklib er det vigtigt, at klubben har værdier og at alle medlemmer husker dem. Værdierne er med at at sikre at alle trives i klubben og at sammenholdet fungerer, da vi ønsker at klubben skal kunne rumme alle personligheder.
                        </p>
                        <ul>
                            <li>Kammaratskab</li>
                            <li>Respekt</li>
                            <li>Glæde</li>
                            <li>Udvikling</li>
                            <li>Fællesskab</li>
                            <li>Fairplay</li>
                            <li>Loyalitet</li>
                        </ul>
                        <h2 className="text-black" id="information_section">KLUBBENS GRUNDLÆGGELSE</h2>
                        <p>
                            I september 2021 blev der holdt en stiftende generalforsamling som vedtog foreningens vedtægter. Disse vedtægter kan ses her. Foreningen blev dannet på det grundlag at skulle udøve sport til studerende på Aalborg Universitet til studievenlige priser. POLIS I.K er en datterforening af POLIS AAU. For at vedtage POLIS AAUs interesser, så blev det et krav at være medlem at POLIS AAU, for at kunne blive medlem af foreningen.
                        </p>
                        <h2 className="text-black" id="information_section">HVAD SIGER VORES MEDLEMMER?</h2>
                        <div className="bg-white">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
                                    <dl className="space-y-6 divide-y divide-gray-200">
                                        {testimonials.map((people) => (
                                            <Disclosure as="div" key={people.name} className="pt-6">
                                                {({ open }) => (
                                                    <>
                                                        <dt className="text-lg">
                                                            <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                                <span className="font-medium text-gray-900">{people.name}</span>
                                                                <span className="ml-6 h-7 flex items-center">
                                                                    <ChevronDownIcon
                                                                        className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            </Disclosure.Button>
                                                        </dt>
                                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                            <p className="text-base text-gray-500">{people.answer}</p>
                                                        </Disclosure.Panel>
                                                    </>
                                                )}
                                            </Disclosure>
                                        ))}
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FOOTER />
        </>
    )
}
import gallery01 from "../images/gallery/gallery01.jpg"
/* import gallery02 from "../images/gallery/gallery02.jpg" */
import gallery03 from "../images/gallery/gallery03.jpg"
/* import gallery04 from "../images/gallery/gallery04.jpg" */
import gallery05 from "../images/gallery/gallery05.jpg"
import gallery06 from "../images/gallery/gallery06.JPG"
import gallery07 from "../images/gallery/gallery07.jpg"
import gallery08 from "../images/gallery/gallery08.png"
import gallery09 from "../images/gallery/gallery09.png"
import gallery10 from "../images/gallery/gallery10.png"
import gallery11 from "../images/gallery/gallery11.png"

export const product = {
    name: 'POLIS IK Logo',
    description: 'Så er der eddermame professionalisme på højeste plan i POLIS I.K!! Vores dygtige grafiker Jacob Kyed Andreassen (@kyed3) har nemlig lavet vores logo og det er bare flot! 🔥🔥 Dette logo kommer til at være på vores trøjer, og vil bringe stolthed til vores forening. Vi siger tusind tak til Jacob fordi han ville lave et logo til os. Han er dælme dygtig og så er han oven i købet også flink. Så vi giver ham lige et kæmpe shout out 👏👏',
    imageSrc: gallery08,
    link: 'https://www.instagram.com/p/CTZqLsBM22O/',
}

export const image1 = {
    name: 'POLIS IK x Sportmaster',
    description: "Det er os en stor glæde at præsentere vores samarbejdsaftale med #sportmaster 🤝 Vi ser frem til et godt og langvarigt partnerskab! På grund af dem er det muligt at skaffe endnu mere udstyr samt spillertrøjer! 🏆⚽️ Vi er sikker på at aftalen vil hjælpe klubbens fremdrift mod superligaen 😉",
    imageSrc: gallery09,
    link: 'https://www.instagram.com/p/CZ1f0EdtI7m/',
}

export const image2 = {
    name: 'POLIS IK x Nordeafonden',
    description: 'Vi i POLIS I.K siger tusind tak til Nordea Fonden for at støtte os med hele 8.150kr! 💙 Det er et betydeligt beløb, som forudsætter, at vi kan købe bedre udstyr til den kommende sæson 🤝 På grund af dem er det muligt at holde træninger, der er på niveau med de større Fodbold klubber i Aalborg, hvor vi samtidigt fastholder Aalborgs billigste kontingent på 250kr halvårligt! Nu får en flok fattige studerende på S.U muligheden for en mere lukrativ træning 💪⚽️ Endvidere vil vi gerne gøre folk opmærksomme på Nordea Fonden! Det er en fantastisk fond, som støtter lokale foreninger. Tjek deres Instagram ud 😉',
    imageSrc: gallery10,
    link: 'https://www.instagram.com/p/CZ9ikeXt1M3/',
}

export const image3 = {
    name: 'POLIS IK x Sparekassen Danmark',
    description: 'Vi er enormt begejstrede for endelig at præsentere vores samarbejdsaftale med #SparekassenDanmark! 🟢🤝🔵 Det er I deres interesse at støtte det sociale miljø for studerende på Aalborg Universitet 📚. Derfor forfalder det naturligt, at etablere et samarbejde, således studerende på #AAU har mulighed for at dyrke sport til den billigste pris! 💙 TUSIND TAK til Sparkassen Danmark, og tusind tak for, at I gør livet nemmere for studerede på AAU 💙💙 Vi ses i Superligaen om et par sæsoner 😉💪',
    imageSrc: gallery11,
    link: 'https://www.instagram.com/p/CaNJ2o8NuLA/',
}

export const image4 = {
    name: 'POLIS IK x Sportmaster',
    description: "Det er os en stor glæde at præsentere vores samarbejdsaftale med #sportmaster 🤝 Vi ser frem til et godt og langvarigt partnerskab! På grund af dem er det muligt at skaffe endnu mere udstyr samt spillertrøjer! 🏆⚽️ Vi er sikker på at aftalen vil hjælpe klubbens fremdrift mod superligaen 😉",
    imageSrc: gallery07,
    link: 'https://www.instagram.com/p/CZ1f0EdtI7m/',
}

export const image5 = {
    name: 'POLIS IK x POLIS AAU',
    description: "Tak for en pisse god julefrokost! 👏🎄 - Grundet Covid blev mange af jer hindret i at komme, og det er mega ærgerligt. ALLIGEVEL blev det til en fantastisk julefrokost! (Lige før vi manglede mere snaps…..🤮) - 💙Det var så fedt at se den opbakning og engagement, der var til evalueringen og målsætningen for næste sæson. Os i bestyrelsen bliver så glade når vi ser, at ambitionerne for denne klub er gensidige - 💰Vi fik blandt andet revideret bødekassen! Der vil komme et opslag om tiltagene senere 🤝 - 🏆Kåringerne var en succes! Selvom nogle af jer måske er utilfredse med jeres stats (især angående fysikken 😉💪). MEN DISSE FUTKORT kan altid opgraderes! Så giv den gas til næste sæson ⚽️⚽️⚽️",
    imageSrc: gallery06,
    link: 'https://www.instagram.com/p/CXVz3PoNIko/',
}

export const image6 = {
    name: 'POLIS IK x Sparekassen Danmark',
    description: 'Vi er enormt begejstrede for endelig at præsentere vores samarbejdsaftale med #SparekassenDanmark! 🟢🤝🔵 Det er I deres interesse at støtte det sociale miljø for studerende på Aalborg Universitet 📚. Derfor forfalder det naturligt, at etablere et samarbejde, således studerende på #AAU har mulighed for at dyrke sport til den billigste pris! 💙 TUSIND TAK til Sparkassen Danmark, og tusind tak for, at I gør livet nemmere for studerede på AAU 💙💙 Vi ses i Superligaen om et par sæsoner 😉💪',
    imageSrc: gallery05,
    link: 'https://www.instagram.com/p/CXVz3PoNIko/',
}

export const image7 = {
    name: 'POLIS IK vs KB81',
    description: "That winning feeling 🤩 TUSIND TAK FOR ALLE DEM DER KOM IDAG!!! Mega fedt at se så meget opbakning 💙💙💙",
    imageSrc: gallery03,
    link: 'https://www.instagram.com/p/Cb2rNpdFGtv/',
}

export const image8 = {
    name: 'POLIS IK vs MOU IF',
    description: "Jamen det skulle jo ske. Det skulle jo ske. POLIS I.K skulle jo opleve at tabe en kamp. Det er ikke nemt at være ubesejret. Så vi tager ikke nederlaget så tungt 🤌 MEN på trods af resultatet så var POLIS I.K offensiven on 🔥🔥🔥 idag. Kæft de drenge gav Mou suppe CB’s en identitetskrise der sagde spar to. Altså er I forsvarsspillere eller er I bare suppe? 🤷🍜 Især gjorde en spiller en ekstra indsats i offensiven, og det var Nicklas Momme 🤩 “What a Momme Show”. Kæft som den mand kunne glide sig forbi modstanderen som en nybarberet ål🧞‍♀️ Mou Suppe scorede 4 heldige mål grundet en pludselig sygdom vores CB backs blev smittet med 🦠 - nemlig “dårlig kondi”.",
    imageSrc: gallery01,
    link: 'https://www.instagram.com/p/CcjCV4fFVWJ/',
}


import Titles from '../components/global-variables'
import FOOTER from '../components/footer'
import HEADER from '../components/header'
import img_wallpaper from "../images/wallpaper.jpg"
import img_michelsen from "../images/boardmembers/michelle.jpg"
import img_cooper from "../images/boardmembers/coops.jpg"
import img_kyed from "../images/boardmembers/jacobi.jpg"
import img_molin from "../images/boardmembers/molle.jpg"
import img_pedersen from "../images/boardmembers/capper.jpg"
import img_horsted from "../images/boardmembers/viggo.jpg"
import img_juel from "../images/boardmembers/juels.jpg"
import React, { useEffect } from 'react';

const people = [
  {
    name: 'Mads Molin',
    role: 'Formand',
    image: img_molin,
  },
  {
    name: 'Sebastian Mccall Cooper',
    role: 'Næstformand',
    image: img_cooper,
  },
  {
    name: 'Jacob Kyed Andreassen',
    role: 'Kasserer',
    image: img_kyed,
  },
  {
    name: 'Casper Jahreis Pedersen',
    role: 'Bestyrelsesmedlem',
    image: img_pedersen,
  },
  {
    name: 'Victor Horsted',
    role: 'Bestyrelsesmedlem',
    image: img_horsted,
  },
  {
    name: 'Victor Michelsen',
    role: 'Bestyrelsesmedlem',
    image: img_michelsen,
  },
  {
    name: 'Phillip Juel Nielsen',
    role: 'Bestyrelsesmedlem',
    image: img_juel,
  },
]

export default function BOARDMEMBERS() {

  useEffect(() => {
    document.title = "Bestyrelsen | POLIS IK";
  });

  return (
    <>
      <HEADER />
      <div className="relative bg-black">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src={img_wallpaper}
            alt=""
          />
          <div className="absolute inset-0 bg-black opacity-60" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">{Titles.boardmembersTitle}</h1>
        </div>
      </div>
      <div className="bg-white">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-black">BESTYRELSEN</h2>
              <p className="text-xl text-gray-500">
                Dette er den nuværende bestyrelse som sikrer klubbens drift, lever op til sine juridiske forpligtelser og sikrer at vedtægterne overholdes. Bestyrelse sikrer ydermere klubbens fremtid og gør at medlemmerne af foreningen har indsigt i og indflydelse på foreningen.
              </p>
            </div>
            {/* role="list" */}
            <ul
              className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
              {people.map((person) => (
                <li key={person.name}>
                  <div className="space-y-4">
                    <div className="aspect-w-3 aspect-h-2">
                      <img className="object-cover shadow-lg rounded-lg" src={person.image} alt="" />
                    </div>
                    <div className="space-y-2">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>{person.name}</h3>
                        <p className="text-blue-600">{person.role}</p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <FOOTER />
    </>
  )
}
import Titles from '../components/global-variables'
import FOOTER from '../components/footer'
import HEADER from '../components/header'
import img_wallpaper from "../images/wallpaper.jpg"
import polisik_logo from "../images/logo.png"
import React, { useState, useEffect } from 'react';
import { send } from '@emailjs/browser';
const { REACT_APP_API_KEY, REACT_APP_TEMPLATE_ID_TRIAL, REACT_APP_SERVICE_ID } = process.env

export default function TRIALSIGNUP() {
    const [sendTo, setToSend] = useState({
        full_name: '',
        email_address: '',
        phone_no: '',
        sport_class: '',
    });

    const formOn = true;
    const [message, setMessage] = useState();
    const [buttonMessage, setButtonMessage] = useState("Opskriv mig");
    const [showSuccess, setShowSuccess] = useState();

    const onSubmit = (e) => {
        e.preventDefault();
        if (sendTo.full_name === '') {
            setMessage("Indtast venligst dit fulde navn");
            return -1;
        }
        if (sendTo.email_address === '') {
            setMessage("Indtast venligt din email");
            return -1;
        }
        if (sendTo.phone_no === '') {
            setMessage("Indtast venligt dit telefon nr.");
            return -1;
        }
        if (sendTo.sport_class === '') {
            setMessage("Vælg venligst en afdeling");
            return -1;
        }
        if (formOn === false) {
            setButtonMessage("Fejl opstået");
            setMessage("Tak fordi du har lyst til en prøveperiode hos os! Funktionen er desværre midlertidigt slået fra. Prøv venligst igen på et senere tidspunkt.")
            return -1;
        }
        setButtonMessage("Vent venligst...");
        send(
            `${REACT_APP_SERVICE_ID}`,
            `${REACT_APP_TEMPLATE_ID_TRIAL}`,
            sendTo,
            `${REACT_APP_API_KEY}`,
        )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                localStorage.setItem('trial-sent', true);
                setShowSuccess(true);
            })
            .catch((err) => {
                console.log('FAILED...', err);
                setMessage("Tak fordi du har lyst til en prøveperiode hos os! Funktionen er desværre midlertidigt slået fra. Prøv venligst igen på et senere tidspunkt.")
                setButtonMessage("Fejl opstået");
            });
    };

    const handleChange = (e) => {
        setToSend({ ...sendTo, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        document.title = "Prøveperiode | POLIS IK";
    });

    return (
        <>
            <HEADER />
            <div className="relative bg-black">
                <div className="absolute inset-0">
                    <img
                        className="w-full h-full object-cover"
                        src={img_wallpaper}
                        alt=""
                    />
                    <div className="absolute inset-0 bg-black opacity-60" aria-hidden="true" />
                </div>
                <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">{Titles.trialSignupTitle}</h1>
                </div>
            </div>
            <div className="relative bg-white">
                <div className="absolute inset-0">
                    <div className="absolute inset-y-0 left-0 w-1/2" />
                </div>
                <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                    <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                        <div className="max-w-lg mx-auto">
                            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">HVAD INDEBÆRER PRØVEPERIODEN?</h2>
                            <p className="mt-3 text-lg leading-6 text-gray-500 leading-relaxed">
                                <strong>POLIS IK;</strong> tilbyder en gratis prøveperiode på 14 dage, hvor man har mulighed
                                for at træne 2 gange maksimalt. Hvis dette er noget for dig, så opskriv dig til prøveperioden
                                ved at udfylde formularen til højre.
                            </p>
                            <dl className="-mt-28 text-base text-gray-500">
                                <a href='/' className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                                    <img className="scale-50" src={polisik_logo} alt="Sparekassen Danmark" />
                                </a>
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
                        <div className="max-w-lg mx-auto lg:max-w-none">
                            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl pb-4">OPSKRIV MIG TIL EN PRØVEPERIODE</h2>
                            <div className="flex-1 mt-10 lg:mt-0">
                                {!showSuccess && localStorage.getItem('trial-sent') !== 'true' ?
                                    <>
                                        <form className="grid grid-cols-1 gap-y-6" onSubmit={onSubmit}>
                                            <div>
                                                <label htmlFor="full_name" className="sr-only">
                                                    Fulde navn
                                                </label>
                                                <input name='full_name' value={sendTo.full_name} onChange={handleChange}
                                                    type="text"
                                                    id="full_name"
                                                    autoComplete="name"
                                                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                                                    placeholder="Fulde navn"
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="email" className="sr-only">
                                                    Email
                                                </label>
                                                <input name='email_address' value={sendTo.email_address} onChange={handleChange}
                                                    id="email_address"
                                                    type="email"
                                                    autoComplete="email"
                                                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                                                    placeholder="Email"
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="phone_no" className="sr-only">
                                                    Telefon Nr.
                                                </label>
                                                <input name='phone_no' value={sendTo.phone_no} onChange={handleChange}
                                                    type="text"
                                                    id="phone_no"
                                                    autoComplete="tel"
                                                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                                                    placeholder="Telefon nr."
                                                />
                                            </div>
                                            <label htmlFor="sport_class" className="sr-only">
                                                Afdeling
                                            </label>
                                            <select name='sport_class' value={sendTo.sport_class} onChange={handleChange}
                                                id="sport_class"
                                                className="block w-full shadow-sm py-3 px-4 text-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                                                defaultValue="Vælg en afdeling"
                                            >
                                                <option>Vælg en afdeling</option>
                                                <option>Fodbold</option>
                                                {/* <option>Billard</option>
                                                <option>Håndbold</option> */}
                                            </select>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                                    {buttonMessage}
                                                </button>
                                                <p className="ml-3 mt-5 text-red-500 text-xs italic">{message}</p>
                                            </div>
                                        </form>
                                    </> :
                                    <>
                                        <h1 className="ml-3 dark:text-gray-500">Tak! Vi har modtaget dine oplysninger. Du kan forvente en mail angående nærmere info omkring prøveperioden indenfor kort tid. Hvis du ikke kan se mailen så tjek venligst dine spam mails.</h1>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FOOTER />
        </>
    )
}
import Titles from "../components/global-variables"
import HEADER from "../components/header"
import img_wallpaper from "../images/wallpaper.jpg"
import img_laptop from "../images/laptop.png"
import img_phone from "../images/phone.png"
import React, { useEffect } from 'react';

export default function FOOTBALLPAGE() {

  useEffect(() => {
    document.title = "Fodbold Afdeling | POLIS IK";
  })

  return (
    <>
      <HEADER />
      <div className="relative bg-black">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src={img_wallpaper}
            alt=""
          />
          <div className="absolute inset-0 bg-black opacity-60" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">{Titles.footballTitle}</h1>
        </div>
      </div>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                VELKOMMEN TIL POLIS IK FODBOLD
              </span>
            </h1>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto text-left">
            <p>
              Vores spillere er primært studerende i alderen 19-25 år. Man bliver en del af et større socialt netværk, som er blandt Aalborg Universitets studerende. Selvom vi er en ambitiøs idrætsforening, så har vi dog også et stort fokus på det sociale, som er udenfor banen. Derfor afholdes der også diverse arrangementer og fester, hvilket gør holdet tættere socialt.
            </p>
            <p>
              Lyder vi som en idrætsforening for dig? Så kan du kan finde nærmere information om POLIS I.K her: <a className="text-blue-500" href="#information_section">Information</a> Du er
              mere end velkommen til at like vores <a className="text-blue-500" href="https://www.facebook.com/polis.idraets.klub/">offentlige Facebook side</a> eller ansøge om at blive medlem af vores <a className="text-blue-500" href="https://www.facebook.com/groups/294014312448080">lukket Facebook gruppe</a>.
            </p>
            <p>
              Hvis du har interesse i at spille hos os, så kan du opskrive dig til vores <a href="opskriv-proeveperiode" className="text-blue-500" target="_pop">prøveperiode</a> eller tilmelde dig vores idrætsforening direkte. Efter du har opskrevet dig, så vil du modtage en mail med nærmere detaljer om træningstider og hvor du skal møde. Vi glæder os til at se dig!
            </p>
            <h2 className="text-black" id="information_section">INFORMATION</h2>
            <strong className="text-gray-900">Ny spiller i POLIS I.K Fodbold eller lyster en prøvetræning blot?</strong>
            <p>
              Alle nye er velkommen til 2 gange gratis prøvetræning hos POLIS I.K Fodbold. Herefter vil man være forpligtet til at betale kontingent. Det er ikke et krav at meddele om du kommer, men du har krav på at opskrive dig til den gratis prøveperiode, hvis du vil gøre brug af denne mulighed, ved at udfylde denne <a href="opskriv-proeveperiode" className="text-blue-500" target="_pop">formular</a>. Du kan også tilmelde dig direkte uden at gøre brug af prøveperioden. Hvis du ønsker at tale med en af trænerne eller vores bestyrelse inden, så er du selvfølgelig også altid velkommen til at kontakte dem. Kontaktoplysninger til bestyrelsen og trænere kan findes under fanen "Kontakt".
            </p>
            <p>
              <strong className="text-gray-500">Nuværende Fodbold Hold:</strong>
              <ul>
                <li>Herre Serie 5 - (DBU Tilmeldt)</li>
              </ul>
            </p>
            <p>
              POLIS I.K Fodbold har hjemmebane på Domen (AAU), Thomas Manns Vej 20, 9220 Aalborg
            </p>
            <h2 className="text-black">SPILLERCERTIFIKAT</h2>
            <p>
              Når du starter i POLIS I.K Fodbold, så er det vigtig at du medbringer dit spillercertifikat fra din gamle klub. Hvis du ikke har dit, spillercertifikat, så kan vi heldigvis ansøge om det fra din gamle klub. Hvis du ikke har spillet fodbold i mere end to år, så opretter vi blot et nyt og det samme gælder selvfølgelig, hvis du aldrig har spillet fodbold før.
            </p>
            <p>
              Du kan melde dig ind/ud i vores DBU klub ved at bruge nedestående links:
              <br></br>
              <br></br>
              <strong className="text-gray-500">Herre:</strong>
              <br></br>
              <a className="text-blue-500" href="https://koservice.dbu.dk/ClubSignup?id=1&clubid=5476" target="_pop">Indmeldelse i herre afdelingen</a>.
              <br></br>
              <a className="text-blue-500" href="https://kluboffice.dbu.dk//dbu/memberresignrequest?clubid=89473b66-c4c0-4434-aaca-4f85ed93a364" target="_pop">Udmeldelse af herre afdelingen</a>.
            </p>
            <h2 className="text-black">KONTINGENT</h2>
            <p>
              Kontingent for POLIS I.K Fodbold er sat til <strong className="text-gray-900">250 kr per halve år for studerende på Aalborg Universitet.</strong>
            </p>
            <p>
              Kontingentet skal betales senest <strong className="text-gray-900">1. oktober</strong> og <strong className="text-gray-900">1. februar</strong>. Således betales for september-december og fra januar-maj. Betales kontingentet ikke vil det ikke være muligt at træne med eller spille kampe.
            </p>
            <p>
              Begynder du midt i en sæson, så har du en prøveperiode på 14 dage, så længe du har opskrevet dig til den. Derefter vil du blive opkrævet at betale kontingent. Bemærk da kontingentet er så lavt i forhold til andre idrætsklubber, så vil der <strong className="text-gray-900">IKKE</strong> være mulighed for en reduktion i kontingentet.
            </p>
            <strong className="text-gray-900">Kontingent:</strong>
            <br></br>
            Betalingen af kontingent kan gøres på følgende to måder:
            <br></br>
            1. Ved at overføre det pågældende beløb til følgende konto i Jutlander Danmark:
            <ul> {/* role="list" */}
              <li>Reg. nr.: 9070</li>
              <li>Konto nr.: 9210188883</li>
            </ul>
            Husk at skrive dit fulde navn i "Besked til modtager" og informer en fra trænerstablen om at det er overført, så det kan videregives til kasseren.
            <p>
              2. Betaling via appen "<a className="text-blue-500" href="https://web.minforening.dk/invitation?clubToken=rph3aeBAO5VQHHMtj_nO1DR5jFRzhmLjkkABFNI7YVifo5FfCJ6ia25luwCGoO102GClzZv0N3SzoCs4RStTcQ%3D%3D" target="_pop">MinForening</a>". Mere info omkring denne fås af træneren til træning. Opret dig dog gerne på appen, hvis du ønsker at spille.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="flex flex-col justify-between max-w-xl px-4 mx-auto lg:flex-row md:px-8 lg:max-w-screen-xl">
          <div className="pt-16 mb-16 lg:mb-0 lg:pt-32 lg:max-w-lg lg:pr-5 py-48">
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                EN APP TIL AT SE TRÆNINGER
                <br className="hidden md:block" />
                OG KAMPE SAMT{' '}
                <span className="inline-block text-blue-500">
                  BETALING AF KONTINGENT
                </span>
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                POLIS IK bruger en app der hedder 'MinForening' til at holde styr på, hvem der der kommer til træning samt kampe. Ydermere bliver appen brugt til at betale kontingent over.
              </p>
            </div>
            <div className="flex items-center">
              <a
                href="https://minforening.dk/"
                aria-label=""
                className="inline-flex items-center font-semibold transition-colors duration-200 text-blue-500 hover:text-blue-600"
              >
                Læs mere
              </a>
              <a
                href="https://web.minforening.dk/invitation?clubToken=rph3aeBAO5VQHHMtj_nO1DR5jFRzhmLjkkABFNI7YVifo5FfCJ6ia25luwCGoO102GClzZv0N3SzoCs4RStTcQ%3D%3D"
                className="inline-flex items-center justify-center h-12 px-6 ml-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-500 hover:bg-blue-600 focus:shadow-outline focus:outline-none"
              >
                Tilmeld dig
              </a>
            </div>
          </div>
          <div className="flex items-center justify-center lg:w-1/2">
            <div className="w-500">
              <img className="object-cover" src={img_laptop} alt="" />
            </div>
            <div className="w-200 -ml-16 lg:-ml-32">
              <img className="object-cover" src={img_phone} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="relative bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="prose prose-indigo prose-lg text-gray-500 mx-auto text-left pb-20">
            <h2 className="text-black">TRÆNINGSTIDER</h2>
            <p>
              POLIS I.K Fodbold træner som udgangspunkt to gange i ugen på Domen (AAU).
            </p>
            <p>
              Herre:
            </p>
            <ul> {/* role="list" */}
              <li>Mandag - kl. 19.00-21.00 - Domen (AAU)</li>
              <li>Onsdag - kl. 19.00-21.00 - Domen (AAU)</li>
            </ul>
            <p>
              Kvinder:
            </p>
            <ul> {/* role="list" */}
              <li>Mandag - kl. 17.30-19.00 - Domen (AAU)</li>
              <li>Onsdag - kl. 17.30-19.00 - Domen (AAU)</li>
            </ul>
            <br></br>
            <i>Hvis hindringer i forhold til træninger sker, så meldes dette ud. Dette er enten i den lukkede Facebook gruppe eller ved at aflyse træningen over MinForening.</i>
          </div>
        </div>
      </div>
    </>
  )
}
import Titles from "../components/global-variables"
import img_wallpaper from "../images/wallpaper.jpg"
import HEADER from "../components/header"
import FOOTER from "../components/footer"
import React, { useEffect } from 'react';

export default function SIGNUP() {

    useEffect(() => {
        document.title = "Tilmeld dig | POLIS IK";
    });

    return (
        <>
            <HEADER />
            <div className="relative bg-black">
                <div className="absolute inset-0">
                    <img
                        className="w-full h-full object-cover"
                        src={img_wallpaper}
                        alt=""
                    />
                    <div className="absolute inset-0 bg-black opacity-60" aria-hidden="true" />
                </div>
                <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">{Titles.signupTitle}</h1>
                </div>
            </div>
            <div className="relative py-16 bg-white overflow-hidden">
                <div className="relative md:bg-white md:p-6">
                    <p className="text-3xl pb-6 leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        HVORDAN TILMELDER JEG MIG POLIS IK?
                    </p>
                    <div className="lg:grid lg:grid-cols-4 lg:gap-6">
                        <div className="lg:col-start-2 col-span-2 prose prose-blue prose-lg text-gray-500 lg:max-w-none leading-loose">
                            <p>
                                Dette vil være en kort guide til hvordan du tilmelder dig POLIS IK korrekt, så der ikke opstår fejl.
                                Der er et par forskellige platforme man skal registrere sig på, hvis man ikke allerede er registreret
                                på dem.
                                <br></br>
                                <br></br>
                                <strong className="text-gray-500">Dette er de forskellige trin:</strong>
                            </p>
                            <ol className="text-left">
                                <li>Tilmeld dig MinForening ved brug af nedenstående link. Når du er oprettet vil du blive accepteret,
                                    dette kan tage et stykke tid, men plejer at gå relativt hurtigt.
                                </li>
                                <li>
                                    Før du kan betale for medlemsskab skal du først være medlem af POLIS AAU. Det koster 100 kr og
                                    er et engangsbeløb, der betales. Man vil så være medlem resten af ens studietid. Du kan blive
                                    medlem af POLIS AAU ved at klikke <a href="https://polis.nemtilmeld.dk/24/?fbclid=IwAR3jFhCoNExFX_5Q6jq3ZdAArrJN1207iB2ftdUec6D9_iH7wgMbc7wBjig" className="text-blue-500" target="_pop">her</a>.
                                </li>
                                <li>
                                    Når du er blevet POLIS AAU medlem og er blevet accepteret på MinForening, så vil du have muligheden for
                                    at betale kontingent over MinForening appen med MobilePay eller du kan lave en bank overførsel.
                                </li>
                            </ol>
                            <ul className="text-left">
                                <li>Hvis bankoverførsel vælges, så overføres et beløb på 250 kr til følgende konto i
                                    Jutlander Danmark:</li>
                                <li>Reg. nr.: 9070</li>
                                <li>Konto nr.: 9210188883</li>
                                <li>Husk at skrive dit fulde navn i "Besked til modtager" og informer en fra bestyrelsen om at
                                    det er overført, så det kan videregives til kasseren.</li>
                            </ul>
                            <p>
                                <i>Hvis problemer skulle opstå eller du er i tvivl om noget, så send os med glæde en mail.
                                </i>
                            </p>
                            <p>
                                Når alle trin er udført så er du officielt medlem af POLIS IK og kan nyde
                                vores fordele, samt opbygge dit netværk og have det sjovt i et nyt socialt fællesskab.
                                Vi glæder os til at se dig!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
                    <div className="divide-y-2 divide-gray-200">
                        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">LINKS TIL TILMELDING</h2>
                            <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">MinForening Tilmelding</h3>
                                    <dl className="mt-2 text-base text-gray-500">
                                        <a
                                            href="https://web.minforening.dk/invitation?clubToken=rph3aeBAO5VQHHMtj_nO1DR5jFRzhmLjkkABFNI7YVifo5FfCJ6ia25luwCGoO102GClzZv0N3SzoCs4RStTcQ%3D%3D"
                                            target="_pop"
                                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        >
                                            Tilmeld mig
                                        </a>
                                    </dl>
                                </div>
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Opskriv dig til prøveperioden</h3>
                                    <dl className="mt-2 text-base text-gray-500">
                                        <a
                                            href="opskriv-proeveperiode"
                                            target="_pop"
                                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        >
                                            Opskriv mig
                                        </a>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div className="mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-8">
                            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">DBU FODBOLD LINKS</h2>
                            <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Indmeldelse i herre afdelingen</h3>
                                    <dl className="mt-2 text-base text-gray-500">
                                        <a
                                            href="https://koservice.dbu.dk/ClubSignup?id=1&clubid=5476"
                                            target="_pop"
                                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        >
                                            Indmeldelse
                                        </a>
                                    </dl>
                                </div>
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Udmeldelse af herre afdelingen</h3>
                                    <dl className="mt-2 text-base text-gray-500">
                                        <a
                                            href="https://kluboffice.dbu.dk//dbu/memberresignrequest?clubid=89473b66-c4c0-4434-aaca-4f85ed93a364"
                                            target="_pop"
                                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        >
                                            Udmeldelse
                                        </a>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FOOTER />
        </>
    )
}
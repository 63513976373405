import Titles from "../components/global-variables";
import img_wallpaper from "../images/wallpaper.jpg"
import HEADER from "../components/header"
import FOOTER from "../components/footer"
import React, { useEffect } from 'react';

export default function QUICKLINKS() {

  useEffect(() => {
    document.title = "Hurtige Links | POLIS IK";
  });

  return (
    <>
      <HEADER />
      <div className="relative bg-black">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src={img_wallpaper}
            alt=""
          />
          <div className="absolute inset-0 bg-black opacity-60" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">{Titles.linksTitle}</h1>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="divide-y-2 divide-gray-200">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">DBU LINKS</h2>
              <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Indmeldelse i afdelingen</h3>
                  <dl className="mt-2 text-base text-gray-500">
                    <a
                      href="https://koservice.dbu.dk/ClubSignup?id=1&clubid=5476"
                      target="_pop"
                      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Indmeldelse
                    </a>
                  </dl>
                </div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Udmeldelse af afdelingen</h3>
                  <dl className="mt-2 text-base text-gray-500">
                    <a
                      href="https://kluboffice.dbu.dk//dbu/memberresignrequest?clubid=89473b66-c4c0-4434-aaca-4f85ed93a364"
                      target="_pop"
                      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Udmeldelse
                    </a>
                  </dl>
                </div>
              </div>
            </div>
            <div className="mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-8">
              <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">SOCIALE MEDIER</h2>
              <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Facebook Side</h3>
                  <dl className="mt-2 text-base text-gray-500">
                    <a
                      href="https://www.facebook.com/polis.idraets.klub/"
                      target="_pop"
                      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Offentlig Side
                    </a>
                  </dl>
                </div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Facebook Fodbold Gruppe</h3>
                  <dl className="mt-2 text-base text-gray-500">
                    <a
                      href="https://www.facebook.com/groups/294014312448080"
                      target="_pop"
                      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Lukket Gruppe
                    </a>
                  </dl>
                </div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Instagram Side</h3>
                  <dl className="mt-2 text-base text-gray-500">
                    <a
                      href="https://www.instagram.com/polis_idraetsklub/"
                      target="_pop"
                      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Instagram
                    </a>
                  </dl>
                </div>
              </div>
            </div>
            <div className="mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-8">
              <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">DIVERSE</h2>
              <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">MinForening Tilmelding</h3>
                  <dl className="mt-2 text-base text-gray-500">
                    <a
                      href="https://web.minforening.dk/invitation?clubToken=rph3aeBAO5VQHHMtj_nO1DR5jFRzhmLjkkABFNI7YVifo5FfCJ6ia25luwCGoO102GClzZv0N3SzoCs4RStTcQ%3D%3D"
                      target="_pop"
                      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Tilmeld Mig
                    </a>
                  </dl>
                </div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Send Os En Mail</h3>
                  <dl className="mt-2 text-base text-gray-500">
                    <a
                      href="mailto:polisboldklub@gmail.com"
                      target="_pop"
                      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Send Mail
                    </a>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FOOTER />
    </>
  )
}
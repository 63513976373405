import Titles from '../components/global-variables'
import FOOTER from '../components/footer'
import HEADER from '../components/header'
import img_wallpaper from "../images/wallpaper.jpg"
import sparekassendk_logo from "../images/sponsors/sparekassendk.png"
import React, { useState, useEffect } from 'react';
import { send } from '@emailjs/browser';
const  {REACT_APP_API_KEY, REACT_APP_TEMPLATE_ID_SPONSOR, REACT_APP_SERVICE_ID} = process.env

const sponsors = [
  { name: 'Sparekassen Danmark', place: 'Aalborghus', adress: 'Kollegievej 2G, 9000 Aalborg', link: 'https://www.spard.dk/sparv/afdelinger/privatafdelinger/aalborghus' },
  { name: 'Sportmaster', place: 'Aalborg Storcenter', adress: 'Hobrovej 452, 9200 Aalborg', link: 'https://sportmaster.dk/butik/sportmaster-aalborg-storcenter' },
]

export default function SPONSORS() {
  const [sendTo, setSendTo] = useState({
    full_name: '',
    phone_no: '',
    email_address: '',
    address_dest: '',
    postal_code: '',
  });

  const formOn = true;
  const [message, setHintText] = useState();
  const [buttonMessage, setButtonText] = useState("Opskriv mig");
  const [showSuccess, setSuccessStatus] = useState();

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (sendTo.full_name === '') {
      setHintText("Indtast venligst dit fulde navn");
      return -1;
    }
    if (sendTo.email_address === '') {
      setHintText("Indtast venligt din email");
      return -1;
    }
    if (sendTo.phone_no === '') {
      setHintText("Indtast venligt dit telefon nr.");
      return -1;
    }
    if (sendTo.address_dest === '') {
      setHintText("Indtast venligst din adresse");
      return -1;
    }
    if (sendTo.postal_code === '') {
      setHintText("Indtast venligst dit postnummer");
      return -1;
    }
    if (formOn === false) {
      setButtonText("Fejl opstået");
      setHintText("Tak fordi du har lyst til at støtte os! Funktionen er desværre midlertidigt slået fra. Prøv venligst igen på et senere tidspunkt.")
      return -1;
    }
    setButtonText("Vent venligst...");
    send(
      `${REACT_APP_SERVICE_ID}`,
      `${REACT_APP_TEMPLATE_ID_SPONSOR}`,
      sendTo,
      `${REACT_APP_API_KEY}`,
    )
      .then((response) => {
        console.log('VELLYKKET!', response.status, response.text);
        localStorage.setItem('sponsor-form', true);
        setSuccessStatus(true);
      })
      .catch((err) => {
        console.log('FEJL!', err);
        setButtonText("Fejl opstået");
        setHintText("Tak fordi du har lyst til at støtte os! Funktionen er desværre midlertidigt slået fra. Prøv venligst igen på et senere tidspunkt.");
      });
  };

  const changeHandler = (e) => {
    setSendTo({ ...sendTo, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    document.title = "Sponsorer | POLIS IK";
  });

  return (
    <>
      <HEADER />
      <div className="relative bg-black">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src={img_wallpaper}
            alt=""
          />
          <div className="absolute inset-0 bg-black opacity-60" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">{Titles.sponsorTitle}</h1>
        </div>
      </div>
      <div className="relative bg-white">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-1/2" />
        </div>
        <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
          <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">STØT POLIS IK I DAG</h2>
              <p className="mt-3 text-lg leading-6 text-gray-500 leading-relaxed">
                <strong>Sparekassen Danmark;</strong> ved at booke en uforpligtende samtale hos Sparekassen Danmark for at høre om, hvad de som bank kan tilbyde dig, kan du støtte POLIS I.K med 500kr. Book dit møde i dag ved at udfylde formularen til højre.
              </p>
              <dl className="-mt-18 text-base text-gray-500">
                <a className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1" href="https://www.spard.dk/sparv/afdelinger/privatafdelinger/aalborghus" target="_pop">
                  <img className="scale-75" src={sparekassendk_logo} alt="Sparekassen Danmark" />
                </a>
              </dl>
            </div>
          </div>
          <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl pb-4">OPSKRIV MIG TIL EN UFORPLIGTENDE SAMTALE</h2>
              <div className="flex-1 mt-10 lg:mt-0">
                {!showSuccess && localStorage.getItem('sponsor-form') !== 'true' ?
                  <>
                    <form className="grid grid-cols-1 gap-y-6" onSubmit={onFormSubmit}>
                      <div>
                        <label htmlFor="full_name" className="sr-only">
                          Fulde navn
                        </label>
                        <input name='full_name' value={sendTo.full_name} onChange={changeHandler}
                          type="text"
                          id="full_name"
                          autoComplete="name"
                          className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          placeholder="Fulde navn"
                        />
                      </div>
                      <div>
                        <label htmlFor="email" className="sr-only">
                          Email
                        </label>
                        <input name='email_address' value={sendTo.email_address} onChange={changeHandler}
                          id="email_address"
                          type="email"
                          autoComplete="email"
                          className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          placeholder="Email"
                        />
                      </div>
                      <div>
                        <label htmlFor="phone_no" className="sr-only">
                          Telefon Nr.
                        </label>
                        <input name='phone_no' value={sendTo.phone_no} onChange={changeHandler}
                          type="text"
                          id="phone_no"
                          autoComplete="tel"
                          className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          placeholder="Telefon nr."
                        />
                      </div>
                      <div>
                        <label htmlFor="adress_dest" className="sr-only">
                          Adresse
                        </label>
                        <input name='address_dest' value={sendTo.address_dest} onChange={changeHandler}
                          type="text"
                          id="address_dest"
                          autoComplete="street-address"
                          className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          placeholder="Adresse"
                        />
                      </div>
                      <div>
                        <label htmlFor="postal_code" className="sr-only">
                          Post Nr.
                        </label>
                        <input name='postal_code' value={sendTo.postal_code} onChange={changeHandler}
                          type="text"
                          id="postal_code"
                          autoComplete="postal_code"
                          className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                          placeholder="Post nr."
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                          {buttonMessage}
                        </button>
                        <p className="ml-3 mt-5 text-red-500 text-xs italic">{message}</p>
                      </div>
                    </form>
                  </> :
                  <>
                    <h1 className="ml-3 dark:text-gray-500">Tak! Vi har modtaget dine oplysninger. Dine oplysninger vil blive videresendt til Sparekassen Danmark. Du kan forvente et opkald fra dem i løbet af de næste par uger.</h1>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 sm:px-6 lg:px-8 bg-white">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl pt-14">VORES SAMARBEJDSPARTNERE</h2>
              <p className="mt-2 text-sm text-gray-700 mb-6">
                Dette er en liste over vores nuværende samarbejdspartnere.
              </p>
            </div>
          </div>
          <ul className="divide-y divide-gray-200">
            {sponsors.map((company) => (
              <li
                className="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600"
              >
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <a href={company.link} target="_pop" className="block focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm font-medium text-gray-900 truncate">{company.name}</p>
                      <p className="text-sm text-gray-500 truncate">{company.place}</p>
                    </a>
                  </div>
                </div>
                <div className="mt-1">
                  <p className="line-clamp-2 text-sm text-gray-600">{company.adress}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="py-16 xl:py-28 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
          <div className="max-w-max lg:max-w-7xl mx-auto">
            <div className="relative z-10 mb-8 md:mb-2 md:px-6">
              <div className="text-base max-w-prose lg:max-w-none">
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  ER DU VORES KOMMENDE SAMARBEJDSPARTNER?
                </p>
              </div>
            </div>
            <div className="relative">
              <svg
                className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
              </svg>
              <svg
                className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
              </svg>
              <div className="relative md:bg-white md:p-6">
                <div className="lg:grid lg:grid-cols-4 lg:gap-6">
                  <div className="lg:col-start-2 col-span-2 prose prose-blue prose-lg text-gray-500 lg:max-w-none leading-loose">
                    <p>
                      At drive en idrætsforening kræver en sund økonomi. Derfor er vi er afhængige af vores
                      samarbejdspartnere og deres bidrag. Som samarbejdspartner med POLIS I.K er du/I med til at gøre det økonomisk
                      muligt at drive idrætsforeningen, som tilgodeser de studerende på Aalborg Universitet og derved også med til
                      at gøre foreningen mere attraktiv.
                    </p>
                    <p>
                      Vi er løbende på udkig efter nye potentielle samarbejdspartnere til vores idrætsforening. Vi vil derfor gerne høre fra dig,
                      hvis du er interesseret i at samarbejde med POLIS I.K, eller har kendskab til en virksomhed i dit netværk, som kunne have
                      interesse i at høre mere om at blive samarbejdspartner med os.
                    </p>
                    <p>
                      Kontakt vores pr-ansvarlig.
                    </p>
                    <a href='mailto:polisboldklub@gmail.com' className='text-blue-500'>
                      polisboldklub@gmail.com
                    </a>
                  </div>
                </div>
                <div className="mt-8 inline-flex rounded-md shadow">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FOOTER />
    </>
  )
}
import img_wallpaper from "../images/wallpaper.jpg"
import React from 'react'
import sportmaster_logo from "../images/sponsors/sportmaster.png"
import sparekassendk_logo from "../images/sponsors/sparekassendk.png"

export default function HEROHEADER() {
  return (
    <div className="bg-white">
      <div>
        {/* Hero card */}
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src={img_wallpaper}
                  alt="POLIS IK Julefrokost"
                />
                <div className="absolute inset-0 bg-black opacity-60" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white">Velkommen til</span>
                  <span className="block text-white">POLIS IK</span>
                </h1>
                <p className="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                  POLIS Idrætsklub, kaldet POLIS I.K, er en forening, som tilbyder en sjov og aktiv hverdag for studerende til SU-venlige priser.
                </p>
                <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                    <a href="om-klubben" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-600 bg-white hover:bg-blue-50 sm:px-8">
                      Læs mere
                    </a>
                    <a
                      href="tilmeld-dig"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 sm:px-8"
                    >
                      Tilmeld dig
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        {/* Logo cloud */}
        <div className="bg-gray-100">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
              Se vores samarbejdspartnere
            </p>
            <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-18" src={sportmaster_logo} alt="Sportmaster Logo" />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-18" src={sparekassendk_logo} alt="Sparekassen Danmark Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      )
}
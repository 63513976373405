import img_wallpaper from "../images/wallpaper.jpg"

export default function NOTFOUNDPAGE() {
    return (
        <div class="bg-blue-900 relative overflow-hidden h-screen">
            <img src={img_wallpaper} class="absolute h-full w-full object-cover" alt="Cover"/>
            <div class="inset-0 bg-black opacity-70 absolute">
            </div>
            <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-32 xl:py-40">
                <div class="w-full font-mono flex flex-col items-center relative z-10">
                    <h1 class="font-extrabold text-5xl text-center text-white leading-tight mt-4">
                        Siden blev ikke fundet
                    </h1>
                    <p class="font-extrabold text-8xl my-44 text-white animate-bounce">
                        404
                    </p>
                </div>
            </div>
        </div>
    )
}